import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class MarksService {

    constructor(
        private httpClient: HttpClient,
    ) { }

    getMarks(): Observable<any> {
        return this.httpClient.get<any>(`${ environment.api }/marks`);
    }

    getUserMarks(): Observable<any> {
        return this.httpClient.get<any>(`${ environment.api }/user_marks`);
    }


    postMark(name: string): Observable<any> {
        return this.httpClient.post<any>(`${ environment.api }/marks`, {
            name
        });
    }

}