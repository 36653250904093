import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class FileStorageService {

    public download(file: any, fileName: string): void {
        const blob = new Blob([file], { type: 'application/octet-stream' }); // { type: "application/force-download" }

        const blobURL = window.URL.createObjectURL(blob);
        const tempLink = document.createElement('a');
        tempLink.style.display = 'none';
        tempLink.href = blobURL;
        tempLink.setAttribute('download', fileName);
        /*
         * Safari thinks _blank anchor are pop ups. We only want to set _blank
         * target if the browser does not support the HTML5 download attribute.
         * This allows you to download files in desktop safari if pop up blocking
         * is enabled.
         */
        if (typeof tempLink.download === 'undefined') {
            tempLink.setAttribute('target', '_blank');
        }
        tempLink.click();
        setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(blobURL);
        }, 100);
    }

}