import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })

export class AdminUsersService {

  constructor(
    private httpClient: HttpClient,
  ) { }

  public get(filters: any, page?: number,) {
    if (filters.roles) {
      filters.roles = filters.roles.join(',') || null;
    }
    if (filters.not_roles) {
      filters.not_roles = filters.not_roles.join(',') || null;
    }
    const filledFilters: any = Object.fromEntries(Object.entries(filters).filter(([_, value]) => value != null));
    if (page !== undefined) {
      return this.httpClient.get<any[]>(`${ environment.api }/users`, {
        params: { page, ...filledFilters }
      })
    }
    else {
      return this.httpClient.get<any[]>(`${ environment.api }/users`, {
        params: { ...filledFilters }
      })
    }
  }

  public getRolesFilters(filters: any) {
    filters.marks = filters.marks.join(',') || null;
    const filledFilters: any = Object.fromEntries(Object.entries(filters).filter(([_, value]) => value != null));
    return this.httpClient.get<any[]>(`${ environment.api }/role`, {
      params: filledFilters
    })
  }

  public getRoles(page?: number) {
    if (page !== undefined) {
      return this.httpClient.get<any[]>(`${ environment.api }/role`, {
        params: { page }
      })
    }
    else {
      return this.httpClient.get<any[]>(`${ environment.api }/role`)
    }
  }

  public deleteRoles(rol_id: number) {
    return this.httpClient.delete<any[]>(`${ environment.api }/role/${ rol_id }`)
  }

  public getUser(id: number) {
    return this.httpClient.get<any>(`${ environment.api }/users/${ id }`)
  }

  public getRole(id: number) {
    return this.httpClient.get<any>(`${ environment.api }/role/${ id }`)
  }

  public getPermissions() {
    return this.httpClient.get<any>(`${ environment.api }/permission`)
  }

  public saveExceptions(id: number, exceptions: any) {
    // console.log(exceptions)
    return this.httpClient.post<any>(`${ environment.api }/exceptions`, {
      model_id: id,
      exceptions: exceptions
    })
  }

  public saveRole(data: any, permiss: any) {
    return this.httpClient.post<any>(`${ environment.api }/role`, {
      ...data,
      permiss: permiss
    })
  }

  public updateRole(data: any, permiss: any, id: number) {
    // console.log(permiss)
    return this.httpClient.put<any>(`${ environment.api }/role/${ id }`, {
      ...data,
      permiss: permiss
    })
  }

  public updateUser(data: any, roles: any, marks: any, id: number, dealer: any, invoiceData: any, webData: any, workersData: any, directions: any) {
    return this.httpClient.put<any>(`${ environment.api }/users/${ id }`, {
      ...data,
      roles: roles,
      marks: marks,
      dealer: dealer,
      invoice_data: invoiceData,
      web_data: webData,
      workers_data: workersData,
      directions: directions
    })
  }

  public createUser(data: any, roles: any, marks: any, dealer: any, invoiceData: any, webData: any, workersData: any, directions: any, vinculate: number | null = null) {
    return this.httpClient.post<any>(`${ environment.api }/users`, {
      ...data,
      roles: roles,
      marks: marks,
      dealer: dealer,
      invoice_data: invoiceData,
      web_data: webData,
      workers_data: workersData,
      directions: directions,
      vinculate: vinculate
    })
  }

  public exportUsers(filters: any, currentMark: number | null, sort: MatSort): Observable<any> {
    const filledFilters = Object.fromEntries(Object.entries(filters).filter(([_, value]) => value != null));
    const params: any = { ...filledFilters };
    if (currentMark) {
      params.mark_id = currentMark;
    }

    if (sort.active && sort.direction) {
      params.order = sort.active;
      params.type_order = sort.direction;
    }

    return this.httpClient.get(`${ environment.api }/export/users`, {
      params,
      responseType: 'arraybuffer'
    });
  }

  public exportRoles(filters: any, currentMark: number | null, sort: MatSort): Observable<any> {
    const filledFilters = Object.fromEntries(Object.entries(filters).filter(([_, value]) => value != null));
    const params: any = { ...filledFilters };
    if (currentMark) {
      params.mark_id = currentMark;
    }

    if (sort.active && sort.direction) {
      params.order = sort.active;
      params.type_order = sort.direction;
    }

    return this.httpClient.get(`${ environment.api }/roles/export`, {
      params,
      responseType: 'arraybuffer'
    });
  }

  public exportPermissions(filters: any, currentMark: number | null, sort: MatSort): Observable<any> {
    // const filledFilters = Object.fromEntries(Object.entries(filters).filter(([_, value]) => value != null));
    // const params: any = { ...filledFilters };
    // if (currentMark) {
    //   params.mark_id = currentMark;
    // }

    // if (sort.active && sort.direction) {
    //   params.order = sort.active;
    //   params.type_order = sort.direction;
    // }

    return this.httpClient.get(`${ environment.api }/permissions/export`, {
      // params,
      responseType: 'arraybuffer'
    });
  }

  public import(file: File): Observable<any> {
    let formData = new FormData();
    formData.append('file', file, file.name);

    return this.httpClient.post<any>(`${ environment.api }/import/users`, formData);
  }

  public importRoles(file: File): Observable<any> {
    let formData = new FormData();
    formData.append('file', file, file.name);

    return this.httpClient.post<any>(`${ environment.api }/import/roles`, formData);
  }

  public deleteUser(userId: number) {
    return this.httpClient.delete<any>(`${ environment.api }/users/${ userId }`)
  }

  public areaManagers() {
    return this.httpClient.get<any>(`${ environment.api }/area_manager`)
  }

  public areaManagerMarks(mark: any) {
    return this.httpClient.get<any>(`${ environment.api }/area_manager_mark/${mark}`)
  }

  public states() {
    return this.httpClient.get<any>(`${environment.api}/states`)
  }

  public cities() {
    return this.httpClient.get<any>(`${environment.api}/cities`)
  }

  public citiesByState(state: any) {
    return this.httpClient.get<any>(`${environment.api}/cities_by_state/${state}`,)
  }

  public setRZMarkZone(rzIE: any, rzDF: any, rzSERES: any, rzSWM: any, dealer: any) {

    return this.httpClient.post<any>(`${environment.api}/rz_mark_zone/${dealer}`, {
      ie: rzIE,
      dfsk: rzDF,
      seres: rzSERES,
      swm: rzSWM
    })
  }

}
