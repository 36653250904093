import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from '../auth/services/auth.service';

@Injectable( { providedIn: 'root' } )

export class ValidarTokenGuard implements CanActivate, CanLoad
{

    constructor( private authService:AuthService, private router:Router) { }

    canLoad(): Observable<boolean> | boolean
    {
        if (!this.authService.hasValidToken()) {
            this.router.navigateByUrl('/login');
            return false;
        }

        return true;
    }

    canActivate(): Observable<boolean> | boolean
    {
        if (!this.authService.hasValidToken()) {
            this.router.navigateByUrl('/login');
            return false;
        }

        return true;
    }
}