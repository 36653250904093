<div class="modal-layout">
  <!-- <ng-container *ngIf="data.roles"> -->
  <div class="modal-title">
    <div>
      <div>
        {{data.title}}
      </div>
    </div>
    <span (click)="dialogRef.close()">X</span>
  </div>
  <!-- </ng-container> -->
  <div>
    {{data.texto}}
  </div>
  <ng-container *ngIf="data?.autoplay == undefined else autoplay">
    <div class="cnt-video">
      <video [src]="data.url" controls="true" controlsList="nodownload" preload="true" width="100%" [class]="data.class">
        Tu navegador no admite el elemento <code>video</code>.
      </video>
    </div>
  </ng-container>
  <ng-template #autoplay>
    <div class="cnt-video">
      <video [src]="data.url" controls="true" controlsList="nodownload" [muted]="'muted'" preload="true" autoplay width="100%" [class]="data.class">
        Tu navegador no admite el elemento <code>video</code>.
      </video>
    </div>
  </ng-template>
  <!-- <form [formGroup]="info" class="modal-form">
      <div class="panel dark-grey features">
        <div class="cnt-all-checkboxs">
          <ng-container *ngFor="let rol of data.roles">
            <label class="cnt-checkbox-default">
              <div class="checkbox-default">
                <input type="radio" [value]="rol.id" name="rol_id" formControlName="rol_id" />
                <i class="fi fi-sr-circle"></i>
              </div>
              <div>
                {{"Rol " + rol.name | translate}}
              </div>
            </label>
          </ng-container>
        </div>
      </div>
    </form>
    <br />
    <div class="ta-center">
      <button [disabled]="info.get('rol_id')?.value === null" (click)="copyPermiss()" class="btn-new">
        <i class="fi fi-rr-duplicate"></i> {{ "Copiar permisos" | translate}}
      </button>
    </div> -->
</div>