import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BoundAttribute } from '@angular/compiler/src/render3/r3_ast';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { academy, environment } from 'src/environments/environment';
import { AuthResponse, User, LoginData} from '../interfaces/interfaces';
import { ModalVideo } from 'src/app/ims-academy/modal-video/modal-video';
import { AcademyService } from 'src/app/services/academy.service';
import { jwtDecode } from 'jwt-decode';

@Injectable({ providedIn: 'root' })

export class AuthService {
  private _user: User | null = null;

  get user() {
    return { ...this._user };
  }

  private _userObservable: Subject<User | null> = new Subject<User | null>();
  public userObservable: Observable<User | null> = this._userObservable.asObservable();

  constructor(
    private http: HttpClient,
    private router: Router,
    public dialog: MatDialog,
    private academyService: AcademyService,
  ) {
    const user_token = localStorage.getItem('user_token');
    if (user_token) {
      const userJson = jwtDecode<LoginData>(user_token);
      this._user =
      {
        id: userJson.id,
        name: userJson.name!,
        rol: userJson.rol,
        rol_name: userJson.rol_name,
        es_admin: userJson.es_admin!,
        first_access: userJson.first_access,
        marcas: userJson.marcas,
        dealer: userJson.dealer,
        permiss: userJson.permiss![0],
      };
    }
  }

  login(username: string, password: string) {
    const body = { username, password };
    return this.http.post<AuthResponse>(`${ environment.api }/auth/login`, body)
      .pipe(
        tap(response => {
          if (response.success == true) {
            let token = response.data!;
            const userData = jwtDecode<LoginData>(token);
            localStorage.setItem('token', userData.access_token!);
            this._user =
            {
              id: userData.id,
              name: userData.name!,
              rol: userData.rol,
              rol_name: userData.rol_name,
              es_admin: userData.es_admin!,
              first_access: userData.first_access,
              marcas: userData.marcas,
              dealer: userData.dealer,
              permiss: userData.permiss![0],
            };

            this._userObservable.next(this._user);

            localStorage.setItem('user_token', token);
          }
        }),
        map(response => response.success),
        catchError(_ => of(false))
      );
  }

  public maybeRedirectUser() {
    if (this.hasValidToken()) {
      //   if (['admin', 'admin-purchase'].includes(this.user?.rol ?? '')) {
      //     // this.router.navigate(['compras']);
      //     this.router.navigate(['dashboard']);
      //   } else {

      this.router.navigate(['dashboard']);
      if (this.user?.first_access) {
        this.openModalVideo(academy.presentacion)
      }
    }
    // }
  }

  public openModalVideo(title: string) {
    let video;
    this.academyService.getVideoSection({ title: title }).subscribe(_ => {
      video = _;
      const dialogRef = this.dialog.open(ModalVideo, {
        data: {
          title: title,
          url: video?.video?.videoUrl,
          autoplay: true
          // texto: texto
        },
        panelClass: 'full-width'
      })
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.router.navigate(['dashboard']);
        }
      });
    })
  }

  public hasValidToken() {
    return this._user !== null;
  }

  logOut() {
    if (this._user !== null) {
      this._user = null;
      this._userObservable.next(null);
      try {
        this.http.get<AuthResponse>(`${ environment.api }/auth/logout`).subscribe();
      } catch (error) {
      }
      localStorage.removeItem('token');
      localStorage.removeItem('brandSelected');
      localStorage.removeItem('user_token');
      this.dialog.closeAll()
      this.router.navigate(['login']);
    }
  }

  resetPassword(email: any) {
    const header = new HttpHeaders({
      'Authorization': 'Basic ' + btoa("email")
    })
    return this.http.get<any>(`${ environment.api }/recover_password/${ email }`, { headers: header });
  }

  changePassword(password: any, passwordVerif: any, token: any) {
    const headers = new HttpHeaders();
    headers.append("Authorization", "Basic " + btoa("password:passwordVerif"))
    return this.http.post<any>(`${ environment.api }/change_password/${ token }`, {
      new_password: password,
      new_password_c: passwordVerif,
      headers
    });
  }

  resetData(password: any, passwordVerif: any, token: any) {
    const headers = new HttpHeaders();
    headers.append("Authorization", "Basic " + btoa("password:passwordVerif"))
    return this.http.post<any>(`${ environment.api }/reset_data/${ token }`, {
      new_password: password,
      new_password_c: passwordVerif,
      headers
    });
  };

  verifyCloudflare(data: any) {
    return this.http.post<any>(`${ environment.api }/verify-turnstile`, data)
  }
}
