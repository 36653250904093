import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdminUsersService } from 'src/app/services/admin/admin-users.service';
import {DomSanitizer} from '@angular/platform-browser';


@Component({
    selector: 'app-modal-video',
    templateUrl: './modal-video.html',
    styleUrls: ['./modal-video.scss']
})

export class ModalVideo {
    // private isCopy: boolean = false;
    public info: FormGroup = new FormGroup({
        rol_id: new FormControl(),
    });
    public permiss = []
    new_url!: any;
    constructor(
        public dialogRef: MatDialogRef<any>,
        private usersService: AdminUsersService,
        public sanitizer: DomSanitizer,

        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.dialogRef.beforeClosed().subscribe(result => this.dialogRef.close(result || this.permiss));
    }
    public close(result?: any) {
        if (result === undefined) {
            result = this.permiss;
        }
        this.dialogRef.close(result);
    }
}
