import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ColorPickerModule } from 'ngx-color-picker';
import { NoYesPipe } from '../pipes/no-yes.pipe';
import { SanitizerPipe } from '../pipes/sanitizer.pipe';
import { StatePipe } from '../pipes/state.pipe';
import { CampaignsCarouselComponent } from './campaigns-carousel/campaigns-carousel.component';
import { ColorpickerComponent } from './colorpicker/colorpicker.component';
import { ConfirmDialog } from './confirm-dialog/confirm-dialog';
import { MaterialModule } from './material.module';
import { PaginatorComponent } from './paginator/paginator.component';
import { RestrictionsComponent } from './restrictions/restrictions.component';
import { UploadFilesComponent } from './upload-files/upload-files.component';
import { SafeHtmlPipe } from '../pipes/safeHtml.pipe';



@NgModule({
  declarations: [
    StatePipe,
    SanitizerPipe,
    NoYesPipe,
    SafeHtmlPipe,
    PaginatorComponent,
    RestrictionsComponent,
    ColorpickerComponent,
    ConfirmDialog,
    UploadFilesComponent,
    CampaignsCarouselComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    ColorPickerModule,
    TranslateModule.forChild(),
  ],
  exports: [
    CommonModule,
    StatePipe,
    SanitizerPipe,
    NoYesPipe,
    SafeHtmlPipe,
    PaginatorComponent,
    RestrictionsComponent,
    ColorpickerComponent,
    ConfirmDialog,
    ReactiveFormsModule,
    MaterialModule,
    TranslateModule,
    UploadFilesComponent,
    CampaignsCarouselComponent
  ]
})
export class SharedModule { }
