<div *ngIf="access else noSurvey" class="public-survey">
    <div>
        <div class="flex ai-center" style="gap: 15px 35px;">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="210" height="56"
                viewBox="0 0 475.779 126.934">
                <defs>
                    <clipPath>
                        <rect width="475.779" height="126.934" fill="none" />
                    </clipPath>
                </defs>
                <g clip-path="url(#clip-path)">
                    <path
                        d="M59.541,6.367V63.019a5.937,5.937,0,0,1-5.934,5.94H53.5a5.938,5.938,0,0,1-5.94-5.94V6.367A5.941,5.941,0,0,1,53.5.43h.1a5.937,5.937,0,0,1,5.937,5.937"
                        transform="translate(87.935 0.795)" class="black" fill="#121211" />
                    <path
                        d="M63.5,69.743h0a5.937,5.937,0,0,1-5.937-5.928V15.333C57.562,6.193,62.941,0,74.006,0c9.746,0,15.33,4.874,17.258,13.506l9.643,41.921a4.632,4.632,0,0,0,9.136,0l9.643-41.921C121.616,4.874,126.692,0,136.541,0c11.062,0,16.444,6.193,16.444,15.333V63.814a5.938,5.938,0,0,1-11.877,0V15.939a4.277,4.277,0,0,0-4.874-4.364,4.352,4.352,0,0,0-4.769,3.655l-9.849,41.927c-2.131,9.034-6.8,13.8-16.238,13.8-9.544,0-14.529-4.971-16.549-13.8L79.082,15.23a4.425,4.425,0,0,0-4.772-3.655A4.272,4.272,0,0,0,69.473,15.2a4.057,4.057,0,0,0-.043.744V63.814a5.937,5.937,0,0,1-5.937,5.937"
                        transform="translate(106.424)" class="black" fill="#121211" />
                    <path
                        d="M152.6,6.361v.1a5.941,5.941,0,0,1-5.937,5.94H117.475c-6.193,0-8.934,3.35-8.934,8.119,0,4.162,2.131,8.122,10.15,8.122h16.042c18.677,0,23.042,10.356,23.042,20.2,0,11.572-6.191,20.1-21.825,20.1H103.718a5.938,5.938,0,0,1-5.937-5.931v-.1a5.937,5.937,0,0,1,5.937-5.937h32.645c6.5,0,9.438-3.553,9.438-8.527,0-4.47-2.333-8.527-10.76-8.527H119.1c-18.273,0-22.537-10.256-22.537-19.794,0-11.37,6.091-19.694,21.318-19.694h28.773A5.937,5.937,0,0,1,152.6,6.359Z"
                        transform="translate(178.529 0.801)" class="black" fill="#121211" />
                    <path
                        d="M464.338,65.77H9.743A7.739,7.739,0,0,1,2,58.032V40.825a7.744,7.744,0,0,1,7.743-7.743h454.6a7.744,7.744,0,0,1,7.743,7.743V58.018a7.744,7.744,0,0,1-7.743,7.743"
                        transform="translate(3.698 61.164)" class="black" fill="#121211" />
                    <path
                        d="M.563,4.2A13.453,13.453,0,0,0,13.9,16.06H123.155a2.81,2.81,0,0,0,2.806-2.806V7.006A2.81,2.81,0,0,0,123.155,4.2Z"
                        transform="translate(1.041 7.765)" class="black" fill="#121211" />
                    <path
                        d="M.563,4.2A13.453,13.453,0,0,0,13.9,16.06H123.155a2.81,2.81,0,0,0,2.806-2.806V7.006A2.81,2.81,0,0,0,123.155,4.2Z"
                        transform="translate(1.041 7.765)" fill="none" stroke="#f4f1f2" stroke-width="1" />
                    <path
                        d="M106.838,23.061a.621.621,0,0,0,.618-.615V11.816a.621.621,0,0,0-.618-.615H10.572a13.455,13.455,0,0,0,13.341,11.86Z"
                        transform="translate(19.546 20.709)" class="black" fill="#121211" />
                    <path
                        d="M106.838,23.061a.621.621,0,0,0,.618-.615V11.816a.621.621,0,0,0-.618-.615H10.572a13.455,13.455,0,0,0,13.341,11.86Z"
                        transform="translate(19.546 20.709)" fill="none" stroke="#f4f1f2" stroke-width="1" />
                    <path
                        d="M88.268,30.061a.618.618,0,0,0,.618-.618V18.816a.621.621,0,0,0-.618-.615H20.616a13.453,13.453,0,0,0,13.341,11.86Z"
                        transform="translate(38.116 33.651)" class="black" fill="#121211" />
                    <path
                        d="M88.268,30.061a.618.618,0,0,0,.618-.618V18.816a.621.621,0,0,0-.618-.615H20.616a13.453,13.453,0,0,0,13.341,11.86Z"
                        transform="translate(38.116 33.651)" fill="none" stroke="#f4f1f2" stroke-width="1" />
                    <path
                        d="M245.964,4.2a13.453,13.453,0,0,1-13.341,11.86H123.369a2.81,2.81,0,0,1-2.806-2.806V7.006A2.81,2.81,0,0,1,123.369,4.2Z"
                        transform="translate(222.904 7.765)" class="black" fill="#121211" />
                    <path
                        d="M245.964,4.2a13.453,13.453,0,0,1-13.341,11.86H123.369a2.81,2.81,0,0,1-2.806-2.806V7.006A2.81,2.81,0,0,1,123.369,4.2Z"
                        transform="translate(222.904 7.765)" fill="none" stroke="#f4f1f2" stroke-width="1" />
                    <path
                        d="M121.18,23.061a.621.621,0,0,1-.618-.615V11.816a.621.621,0,0,1,.618-.615h96.266A13.455,13.455,0,0,1,204.1,23.061Z"
                        transform="translate(222.902 20.709)" class="black" fill="#121211" />
                    <path
                        d="M121.18,23.061a.621.621,0,0,1-.618-.615V11.816a.621.621,0,0,1,.618-.615h96.266A13.455,13.455,0,0,1,204.1,23.061Z"
                        transform="translate(222.902 20.709)" fill="none" stroke="#f4f1f2" stroke-width="1" />
                    <path
                        d="M121.18,30.709a.618.618,0,0,1-.618-.618V19.464a.621.621,0,0,1,.618-.615h67.663A13.453,13.453,0,0,1,175.5,30.709Z"
                        transform="translate(222.902 34.849)" class="black" fill="#121211" />
                    <path
                        d="M121.18,30.709a.618.618,0,0,1-.618-.618V19.464a.621.621,0,0,1,.618-.615h67.663A13.453,13.453,0,0,1,175.5,30.709Z"
                        transform="translate(222.902 34.849)" fill="none" stroke="#f4f1f2" stroke-width="1" />
                    <rect id="Rectángulo_333" data-name="Rectángulo 333" width="3.464" height="15.993"
                        transform="translate(14.29 101.59)" class="white" fill="#fff" />
                    <path d="M19.853,35.66H23.34V51.653H19.787l-9-10.706V51.653H7.346V35.66H10.9l8.954,10.663Z"
                        transform="translate(13.582 65.93)" class="white" fill="#fff" />
                    <path d="M31.7,35.66h3.977L26.417,51.653H23.552L14.29,35.66h4L25,47.278Z"
                        transform="translate(26.42 65.93)" class="white" fill="#fff" />
                    <rect id="Rectángulo_334" data-name="Rectángulo 334" width="3.467" height="15.993"
                        transform="translate(65.242 101.59)" class="white" fill="#fff" />
                    <path
                        d="M28.675,39.127v9.059H41.182v3.467H28.519a3.282,3.282,0,0,1-3.288-3.288V38.95a3.282,3.282,0,0,1,3.288-3.29H41.182v3.467Z"
                        transform="translate(46.649 65.93)" class="white" fill="#fff" />
                    <path d="M47.971,35.66v3.467H41.709V51.653H38.242V39.127H31.98V35.66Z"
                        transform="translate(59.127 65.93)" class="white" fill="#fff" />
                    <path
                        d="M51.5,35.66a3.3,3.3,0,0,1,3.31,3.288V51.653H51.32V46.5H42.258v5.154H38.814V38.948A3.282,3.282,0,0,1,42.1,35.66Zm-.177,3.467H42.258v3.909H51.32Z"
                        transform="translate(71.762 65.93)" class="white" fill="#fff" />
                    <path
                        d="M63.441,35.66H67.02V51.653H63.553V40.925c-1.8,2.134-3.732,4.487-5.51,6.575l-5.53-6.552V51.653H49.069V35.66h3.555l5.419,6.464Z"
                        transform="translate(90.722 65.93)" class="white" fill="#fff" />
                    <path
                        d="M69.623,35.66a3.3,3.3,0,0,1,3.31,3.288V51.653H69.446V46.5H60.384v5.154H56.94V38.948a3.282,3.282,0,0,1,3.288-3.288Zm-.177,3.467H60.384v3.909h9.062Z"
                        transform="translate(105.274 65.93)" class="white" fill="#fff" />
                    <path d="M76.569,35.66h3.49V51.653H76.5l-9-10.706V51.653H64.063V35.66h3.555l8.951,10.663Z"
                        transform="translate(118.443 65.93)" class="white" fill="#fff" />
                    <path
                        d="M83.868,35.66a3.3,3.3,0,0,1,3.31,3.288V51.653H83.691V46.5H74.629v5.154H71.185V38.948a3.282,3.282,0,0,1,3.288-3.288Zm-.177,3.467H74.629v3.909h9.062Z"
                        transform="translate(131.611 65.93)" class="white" fill="#fff" />
                    <path
                        d="M94.3,40.46H90.812V39.127H81.752v9.059h9.059v-2.4H87.35V42.326H94.3v6.04a3.3,3.3,0,0,1-3.313,3.288H81.6a3.278,3.278,0,0,1-3.288-3.288V38.95A3.28,3.28,0,0,1,81.6,35.66h9.4A3.3,3.3,0,0,1,94.3,38.95Z"
                        transform="translate(144.78 65.93)" class="white" fill="#fff" />
                    <path d="M100.189,39.127H88.905v2.8H97.99v3.467H88.905v2.795h11.284v3.467H85.415V35.66h14.774Z"
                        transform="translate(157.921 65.93)" class="white" fill="#fff" />
                    <path
                        d="M106.217,35.66h3.578V51.653h-3.467V40.925c-1.8,2.134-3.732,4.487-5.51,6.575l-5.53-6.552V51.653H91.844V35.66H95.4l5.419,6.464Z"
                        transform="translate(169.807 65.93)" class="white" fill="#fff" />
                    <path d="M114.489,39.127H103.2v2.8h9.085v3.467H103.2v2.795h11.284v3.467H99.715V35.66h14.774Z"
                        transform="translate(184.359 65.93)" class="white" fill="#fff" />
                    <path d="M118.8,35.66h3.49V51.653h-3.555l-9-10.706V51.653h-3.444V35.66h3.555L118.8,46.323Z"
                        transform="translate(196.519 65.93)" class="white" fill="#fff" />
                    <path d="M129.11,35.66v3.467h-6.262V51.653h-3.467V39.127h-6.262V35.66Z"
                        transform="translate(209.141 65.93)" class="white" fill="#fff" />
                    <path
                        d="M139.025,40.438h-3.49v-1.31h-9.059v2.8h9.262a3.294,3.294,0,0,1,3.288,3.288v3.154a3.294,3.294,0,0,1-3.288,3.288h-9.418a3.28,3.28,0,0,1-3.288-3.288V46.879h3.444v1.308h9.059V45.392h-9.216a3.28,3.28,0,0,1-3.288-3.29V38.95a3.28,3.28,0,0,1,3.288-3.29h9.418a3.3,3.3,0,0,1,3.288,3.29Z"
                        transform="translate(227.467 65.93)" class="white" fill="#fff" />
                    <path
                        d="M148.076,35.66,140.658,45.7v5.954h-3.464V45.679l-2.911-3.909c-1.4-1.866-3.242-4.4-4.487-6.111H133.9l5.02,6.31c1.621-2.045,3.4-4.265,5-6.31Z"
                        transform="translate(239.973 65.93)" class="white" fill="#fff" />
                    <path
                        d="M152.96,40.438h-3.49v-1.31H140.41v2.8h9.262a3.294,3.294,0,0,1,3.288,3.288v3.154a3.294,3.294,0,0,1-3.288,3.288h-9.418a3.28,3.28,0,0,1-3.288-3.288V46.879h3.444v1.308h9.059V45.392h-9.216a3.28,3.28,0,0,1-3.288-3.29V38.95a3.28,3.28,0,0,1,3.288-3.29h9.418a3.3,3.3,0,0,1,3.288,3.29Z"
                        transform="translate(253.231 65.93)" class="white" fill="#fff" />
                    <path d="M159.744,35.66v3.467h-6.262V51.653h-3.467V39.127h-6.262V35.66Z"
                        transform="translate(265.78 65.93)" class="white" fill="#fff" />
                    <path d="M165.361,39.127H154.077v2.8h9.085v3.467h-9.085v2.795h11.284v3.467H150.587V35.66h14.774Z"
                        transform="translate(278.414 65.93)" class="white" fill="#fff" />
                    <path
                        d="M171.388,35.66h3.578V51.653H171.5V40.925c-1.8,2.134-3.732,4.487-5.51,6.575l-5.53-6.552V51.653h-3.444V35.66h3.555l5.418,6.464Z"
                        transform="translate(290.3 65.93)" class="white" fill="#fff" />
                </g>
            </svg>
            <div>
                {{'Encuesta sobre la visita realizada el día '}} {{visitInfo.get('date')?.value | date}} {{' a las '}}
                {{visitInfo.get('time')?.value}} {{' por '}} {{visitInfo.get('rz')?.value}}
            </div>
        </div>
        <div class="cnt-surveys">
            <div class="inputs-stars form-button panel gray small" [formGroup]="asFormGroup(data)"
                *ngFor="let data of surveyJobsEval.controls; let i = index">
                {{surveyJobs[i].name}}
                <div class="inputs-radio">
                    <label class="label-star">
                        <input formControlName="note" [value]="1" type="radio" (click)="setNote(i, 1)" />
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path fill="#fff"
                                d="M394 480a16 16 0 01-9.39-3L256 383.76 127.39 477a16 16 0 01-24.55-18.08L153 310.35 23 221.2a16 16 0 019-29.2h160.38l48.4-148.95a16 16 0 0130.44 0l48.4 149H480a16 16 0 019.05 29.2L359 310.35l50.13 148.53A16 16 0 01394 480z" />
                        </svg>
                        <div>1</div>
                    </label>
                    <label class="label-star">
                        <input formControlName="note" [value]="2" type="radio" (click)="setNote(i, 2)" />
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path fill="#fff"
                                d="M394 480a16 16 0 01-9.39-3L256 383.76 127.39 477a16 16 0 01-24.55-18.08L153 310.35 23 221.2a16 16 0 019-29.2h160.38l48.4-148.95a16 16 0 0130.44 0l48.4 149H480a16 16 0 019.05 29.2L359 310.35l50.13 148.53A16 16 0 01394 480z" />
                        </svg>
                        <div>2</div>
                    </label>
                    <label class="label-star">
                        <input formControlName="note" [value]="3" type="radio" (click)="setNote(i, 3)" />
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path fill="#fff"
                                d="M394 480a16 16 0 01-9.39-3L256 383.76 127.39 477a16 16 0 01-24.55-18.08L153 310.35 23 221.2a16 16 0 019-29.2h160.38l48.4-148.95a16 16 0 0130.44 0l48.4 149H480a16 16 0 019.05 29.2L359 310.35l50.13 148.53A16 16 0 01394 480z" />
                        </svg>
                        <div>3</div>
                    </label>
                    <label class="label-star">
                        <input formControlName="note" [value]="4" type="radio" (click)="setNote(i, 4)" />
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path fill="#fff"
                                d="M394 480a16 16 0 01-9.39-3L256 383.76 127.39 477a16 16 0 01-24.55-18.08L153 310.35 23 221.2a16 16 0 019-29.2h160.38l48.4-148.95a16 16 0 0130.44 0l48.4 149H480a16 16 0 019.05 29.2L359 310.35l50.13 148.53A16 16 0 01394 480z" />
                        </svg>
                        <div>4</div>
                    </label>
                    <label class="label-star">
                        <input formControlName="note" [value]="5" type="radio" (click)="setNote(i, 5)" />
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path fill="#fff"
                                d="M394 480a16 16 0 01-9.39-3L256 383.76 127.39 477a16 16 0 01-24.55-18.08L153 310.35 23 221.2a16 16 0 019-29.2h160.38l48.4-148.95a16 16 0 0130.44 0l48.4 149H480a16 16 0 019.05 29.2L359 310.35l50.13 148.53A16 16 0 01394 480z" />
                        </svg>
                        <div>5</div>
                    </label>
                </div>
            </div>
        </div>
        <div>
            <label>
                {{'Comentario' | translate}}
                <textarea [formControl]="comment"></textarea>
            </label>
        </div>
    </div>
    <div class="ta-center button-sticky">
        <button class="btn-new" (click)="sendSurvey()">
            <i class="fi fi-sr-add"></i>
            {{'Guardar encuesta' | translate}}
        </button>
    </div>
</div>
<ng-template #noSurvey>
    <div class="flex ai-center">
        {{'Encuesta no disponible' | translate}}
    </div>
</ng-template>