export const environment =
{
    production: true,
    api: 'https://api.ims-grupoinvicta.es/api',
    // recaptcha:
    // {
    //     siteKey: '6LcbqtQhAAAAAFe4WJwFIYK6Zk1e0fRVm_G1Zr4s',
    // },
    cloudflare:
    {
        siteKey: '0x4AAAAAAAijOUNDpE8tgKlH',
        secretKey: '0x4AAAAAAAijOcq1tVPKjY71q3qiW8TPvzM',
        active: true
    },
    maps:
    {
        key: ''
    },
    urlConfigJson: 'https://ims-grupoinvicta.es/assets/config.json'
};
export const workshopHourPrice = 35;
export const brands =
{
    invicta: 1,
    dfsk: 2,
    swm: 3,
    otros: 4,
    seres: 5,
    baic: 6,
    livan: 7,
};
export const guaranteeFiles =
{
    vin_img_path: 1,
    km_img_path: 2,
    circulation_permit_img_path: 3,
    fault_images: 4,
};
export const vehicleStates =
{
    notReceived: 1,
    requestedDealer: 2,
    available: 3,
    assignedToDealer: 4,
    soldToDealer: 5,
    invoicedToDealer: 6,
    soldToCustomer: 7,
    invoicedToCustomer: 8,
    assignedToCustomer: 9,
};
export const widgetsId =
{
    invictaSales: 1,
    dfskSales: 2,
    swmSales: 3,
    evolutionOfSales: 4,
    requestsToFactory: 5,
    recentSales: 6,
    latestDealerships: 7,
    campaigns: 8,
    news: 9,
    vehiclesRequested: 10,
    campaignsToBeInvoiced: 11,
    campaignsToValidate: 12,
    guaranteesToValidate: 13,
    circulars: 14,
    seresSales: 15,
    myTasks: 16,
    calendar: 17,
    nextVisits: 18,
    newLeads: 19,
    baicSales: 20,
    livanSales: 21
};

export const actionRegisterId =
{
    invoiceModuleAccess: 1,
    invoiceHstAccess: 2,
    invocieHstAccessShow: 3,
    proformaDownload: 4,
    invoiceDownload: 5,
    duplicateInvoiceDownload: 6,
    invoiceGenerate: 7,
    proformaGenerate: 8,
    creditGenerate: 9,
    creditDownload: 10,
    showDataTBAI: 11,
    invoiceHstAccesShowCreditDupl: 12,
    campaignInvoiceDownload: 13,
    duplicateInvoiceCampaignDownload: 14,
    invoiceCampaignGenerate: 15,
    invoiceHstAccesShowInvoiceDupl: 16,
    invoiceGuaranteeDownload: 17,
    duplInvoiceGuaranteeDownload: 18,
    creditGuaranteeDownload: 19,
    duplCreditGuaranteeDownload: 20,
    generateInvoiceGuarantee: 21,
    generateCreditGuarantee: 22
};

export const vehicleDocuments =
{
    buyInvoiceFactory: 1,
    saleInvoice: 2,
    techncialInspection: 3,
    circulationPermit: 4,
    credit: 5,
    campaignInvoice: 6,
    saleInvocieDupl: 7,
    creditDupl: 8,
    campaignInvoiceDupl: 9,
    guaranteeInvoice: 10,
    guaranteeInvoiceDupl: 11,
    guaranteeCredit: 12,
    guaranteeCreditDupl: 13,
    transportDocumentOrigin: 16,
    transportDocumentDestiny: 17,
}

export const tbai = {
    entity: 'SOFTECA INTERNET SL',
    serie: 'XXXXXXXXX',
    soft_name: 'IMS (INVICTA MANAGEMENT SYSTEM)',
    soft_version: '3.9',
    zuzenduSubsanar: 3,
    zuzenduModificar: 4,
}

export const general_config = {
    apply_guarantee: 'Garantías',
    workforce: 'Mano de obra',
    config: 'Cambio de divisas',
    apply_divisa: 'Catálogo',
    descrip_yuan: 'Yuan',
    descrip_dolar: 'Dólar',
}

export const currency = {
    1: '$',
    2: '€',
    3: '¥',
}

export const academy = {
    presentacion: 'Vídeo de presentación',
    pedido_fabrica: 'Solicitud de vehículos a fábrica',
    añadir_catalogo: 'Añadir Vehículos al Catálogo',
    solicitar_vehic_stock: 'Solicitud de vehículos en stock',
    solicitar_vehic_fab: 'Solicitud de vehículos de los que no hay stock inmediato',
    garantia_vehic: 'Solicitud de garantía de un vehículo',
    factura_vehic: 'Facturación de vehículos',
    tbai: 'TicketBAI',
    venta_final: 'Venta Final',
}

export const circular = {
    public_name: 'Nombre público',
    category: 'Categoría'
}

export const bytesMegabytes = {
    MB2: 2097152,
    MB5: 5242880,
    MB10: 10485760,
    MB20: 20971520,
    MB30: 31457280,
    MB40: 41943040,
    MB50: 52428800,
    MB60: 62914560,
    MB70: 73400320,
    MB80: 83886080,
    MB90: 94371840,
    MB100: 104857600,
}

export const CRM = {
    hasClientsOption: true,
    hasVisitsOption: true,
    hasLeadsOption: true,
    hasKPIsOption: true,
    hasTasksOption: true,
}