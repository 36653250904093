import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AcademyService {

  constructor(private httpClient: HttpClient) { }

  public get() {
    return this.httpClient.get<any>(`${ environment.api }/ims_academy`)
  }

  public getVideoSection(params: any) {
    return this.httpClient.get<any>(`${ environment.api }/video_section`, {
      params
    })

  }
}
