import { NgModule } from '@angular/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule, MAT_SELECT_CONFIG } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';


@NgModule({
    imports: [
        MatTableModule,
        MatSortModule,
        MatDialogModule,
        MatTabsModule,
        MatMenuModule,
        MatSnackBarModule,
        MatTooltipModule,
        MatProgressSpinnerModule,
        MatFormFieldModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatProgressBarModule,
        MatDatepickerModule,
        MatMomentDateModule,
        MatInputModule,
        MatSlideToggleModule,
        MatExpansionModule,
    ],
    exports: [
        MatTableModule,
        MatSortModule,
        MatDialogModule,
        MatTabsModule,
        MatMenuModule,
        MatSnackBarModule,
        MatTooltipModule,
        MatProgressSpinnerModule,
        MatFormFieldModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatProgressBarModule,
        MatDatepickerModule,
        MatMomentDateModule,
        MatInputModule,
        MatSlideToggleModule,
        MatExpansionModule,
    ],
    providers: [
        {
            provide: MAT_SELECT_CONFIG,
            useValue: { disableOptionCentering: true }
        }
    ],
})

export class MaterialModule { }