import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AuthService } from '../auth/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserMarkService {

  private _mark: Subject<number> = new Subject();
  public mark: Observable<number> = this._mark.asObservable();
  public currentMark: number | null = null;

  constructor(
    private authService: AuthService,
  ) {
    const current = localStorage.getItem('brandSelected');
    if (current) {
      this.currentMark = parseInt(current);
      this._mark.next(this.currentMark);
    }

    this.authService.userObservable.subscribe(user => {
      if (user) {
        this.changeBrand(user.es_admin ? 0 : user.marcas?.[0]?.id);
      } 
      else {
        this.changeBrand(-1);
      }
    });
  }

  public changeBrand(brandId: any) {
    brandId = parseInt(brandId);
    this.currentMark = brandId;
    this._mark.next(brandId);
  }
}
