import { Component, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, merge, Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, debounceTime, filter, startWith, switchMap, tap, map } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/services/auth.service';
import { CatalogVehiclesService } from 'src/app/services/catalog-vehicles.service';
import { FileStorageService } from 'src/app/services/file-storage.service';
import { UserMarkService } from 'src/app/services/user-mark.service';
import { ConfirmDialog, ConfirmDialogModel } from 'src/app/shared/confirm-dialog/confirm-dialog';
import { PaginatorComponent } from 'src/app/shared/paginator/paginator.component';
import { CRMService } from 'src/app/services/crm.service';
import { NewClientDialog } from '../../crm/modals/new-client-dialog/new-client-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ChangeRZDialog } from '../../crm/modals/change-rz-dialog/change-rz-dialog';
import { MarksService } from 'src/app/services/marks.service';
import Swal from 'sweetalert2';
import { DealersService } from 'src/app/services/dealers.service';
import { CalendarOptions } from '@fullcalendar/core'; // useful for typechecking
import dayGridPlugin from '@fullcalendar/daygrid';
import esLocale from '@fullcalendar/core/locales/es';
import * as moment from 'moment';

@Component({
  templateUrl: './change-visit-rz.component.html',
  styleUrls: ['./change-visit-rz.component.scss']
})
export class ChangeVisitRZComponent {
  public ocSection: any[] = [];
  public access: boolean = false;

  public optionSelected?: any;
  public holdOver?: any;

  public isFormSend?: boolean = false;
  public isOpenCalendar?: boolean = false;
  public isOpenComment?: boolean = false;

  public newDate: FormControl = new FormControl();
  public newHour: FormControl = new FormControl();

  public comment: FormControl = new FormControl();

  openCloseTr: any[] = [];

  events: any[] = [];

  visitsList: any;

  showActions: boolean = false;
  private vehicleSubscription?: Subscription;

  public openSearch = false;

  public showTable = false;

  public isAdmin: any;
  public rol: any;
  public current_mark: any

  public clientTypesList: any[] = []
  public filterClientTypesCtrl: FormControl = new FormControl('');
  private _filteredClientTypes: BehaviorSubject<any> = new BehaviorSubject(null);
  public filteredClientTypes: Observable<any[]> = this._filteredClientTypes.asObservable();

  public dealerList: any[] = [];
  private _filteredDealer: BehaviorSubject<any> = new BehaviorSubject(null);
  public filteredDealer: Observable<any[]> = this._filteredDealer.asObservable();
  public filterDealerCtrl: FormControl = new FormControl('');

  public clientsList: any[] = [];
  private _filteredClients: BehaviorSubject<any> = new BehaviorSubject(null);
  public filteredClients: Observable<any[]> = this._filteredClients.asObservable();
  public filterClientsCtrl: FormControl = new FormControl('');

  public rzList: any[] = []
  public filterRzCtrl: FormControl = new FormControl('');
  private _filteredRz: BehaviorSubject<any> = new BehaviorSubject(null);
  public filteredRz: Observable<any[]> = this._filteredRz.asObservable();

  public originsList?: any[];
  public filterOriginsCtrl: FormControl = new FormControl('');
  private _filteredOrigins: BehaviorSubject<any> = new BehaviorSubject(null);
  public filteredOrigins: Observable<any[]> = this._filteredOrigins.asObservable();

  public visitTypeList: any[] = [];
  public visitStateList: any[] = [];

  private _filteredVisitType: BehaviorSubject<any> = new BehaviorSubject(null);
  public filteredVisitType: Observable<any[]> = this._filteredVisitType.asObservable();
  public filterVisitTypeCtrl: FormControl = new FormControl('');

  private _filteredVisitState: BehaviorSubject<any> = new BehaviorSubject(null);
  public filteredVisitState: Observable<any[]> = this._filteredVisitState.asObservable();
  public filterVisitStateCtrl: FormControl = new FormControl('');

  public token?: any

  private _updateTable: Subject<void> = new Subject();

  public filters: FormGroup = new FormGroup({
    token: new FormControl()
  });


  constructor(
    private titleService: Title,
    private authService: AuthService,
    private userMarkService: UserMarkService,
    private catalogVehiclesService: CatalogVehiclesService,
    private fileStorageService: FileStorageService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private dialog: MatDialog,
    private crmService: CRMService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private markService: MarksService,
    private dealerService: DealersService,

  ) {
    this.titleService.setTitle('IMS - CRM - Cambiar Visita');
    this.token = this.router.url.split('/')[4];
    this.filters.get('token')?.setValue(this.token);
  }


  ngAfterViewInit() {
    this.filters.valueChanges.subscribe(_ => { });
    this.vehicleSubscription = merge(
      this.filters.valueChanges.pipe(debounceTime(1000)),
      merge(
      )
        .pipe(debounceTime(500)),
      this.userMarkService.mark.pipe(tap(_ => { })),
      this._updateTable,
    )
      .pipe(
        startWith(null),
        switchMap(_ => this.crmService
          .validateToken({ ...this.filters.value })
          .pipe(catchError(_ => of([])))
        )
      )
      .subscribe(
        (token: any) => {
          // if (token.total == 0) { this.visitsList = { data: [] }; }
          // else {
          if (token == undefined) {
            //salir de la pagina
            this.access = false
          } else {
            // mostrar pagina
            this.access = true
            this.token = token
            this.optionSelected = token?.option_selected
            this.holdOver = token?.visit.visit_hold_over
            // if (this.holdOver != undefined) {
            //   this.newDate.setValue(this.holdOver.new_date)
            //   this.newHour.setValue(this.holdOver.new_hour)
            // }
            // if (token.visit.visit_canceled != undefined) {
            //   this.comment.setValue(token.visit?.visit_canceled?.motive)
            // }
          }
        }
        // }
      );

    //   console.log(this.videos)
  }

  public openCalendar(open: boolean) {
    this.isOpenCalendar = open
    this.isOpenComment = false
  }

  public openComment(open: boolean) {
    this.isOpenComment = open
    this.isOpenCalendar = false
  }

  public changeState(valueNewState: number) {
    let data: any = []
    if (valueNewState == 3) {
      data.push({ state: valueNewState, new_date: this.newDate.value, new_hour: this.newHour.value })
    }
    if (valueNewState == 2) {
      data.push({ state: valueNewState })
    }
    if (valueNewState == 6) {
      data.push({ state: valueNewState, comment: this.comment.value })
    }
    this.isOpenComment = false;
    this.isOpenCalendar = false;
    this.isFormSend = true;
    this.crmService.changeVisitState(data[0], this.token.visit.id, this.token.id).subscribe(_ => {
      this.optionSelected = _.visit_state_id;
      this.holdOver = _.visit_hold_over;
      this.comment.setValue(_.visit_canceled.motive);
      this.snackBar.open(
        this.translateService.instant(valueNewState == 2 ? 'Se ha aceptado la cita correctamente' : valueNewState == 3 ? 'Se ha propuesto nueva fecha y hora para la cita correctamente' : 'Se ha cancelado la cita correctamente'),
        this.translateService.instant('aceptar'),
        {
          panelClass: 'sb-success',
          duration: 4000
        }
      );
    }, err => {
      this.snackBar.open(
        this.translateService.instant(valueNewState == 2 ? 'Se ha producido un error al aceptar la cita' : valueNewState == 3 ? 'Se ha producido un error al intentar proponer nueva fecha y hora para la cita' : 'Se ha producido un error al cancelar la cita'),
        this.translateService.instant('aceptar'),
        {
          panelClass: 'sb-error',
          duration: 4000
        }
      );
    })
  }

  // ngOnDestroy() {
  //   if (this.vehicleSubscription) {
  //     this.vehicleSubscription.unsubscribe();
  //   }
  // }
}
