import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    private config!: { version: string; }; 

    constructor(
        translate: TranslateService,
        private httpClient: HttpClient
    ) {
        // this language will be used as a fallback when a translation isn't found in the current language
        translate.setDefaultLang('es');

         // the lang to use, if the lang isn't available, it will use the current loader to get them
        translate.use('es');
    }

    ngOnInit() {
      this.config = require('./../assets/config.json'); 
  
      const headers = new HttpHeaders()
        .set('Cache-Control', 'no-cache')
        .set('Pragma', 'no-cache');
  
      this.httpClient.get<{version: string}>(environment.urlConfigJson, {headers})
        .subscribe(config => {
          if (config.version !== this.config.version) {
            this.httpClient
              .get("", { headers, responseType: "text" })
              .subscribe(() => location.reload());
          }
        });
    }

}