import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ValidarTokenGuard } from './guards/validar-token.guard';
import { VisitSurveyComponent } from './crm/visits/visit-survey/visit-survey.component';
import { ChangeVisitComponent } from './crm/visits/change-visit/change-visit.component';
import { ChangeVisitRZComponent } from './crm/visits/change-visit-rz/change-visit-rz.component';

const routes: Routes =
    [
        {
            path: 'login',
            loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
        },
        {
            path: 'visit_survey/:token',
            component: VisitSurveyComponent,
        },
        {
            path: 'change_visit/:token',
            component: ChangeVisitComponent,
        },
        {
            path: 'change_visit_rz/:token',
            component: ChangeVisitRZComponent,
        },
        {
            path: '',
            loadChildren: () => import('./protected/protected.module').then(m => m.ProtectedModule),
            canLoad: [ValidarTokenGuard],
            canActivate: [ValidarTokenGuard]
        },
        {
            path: '**',
            redirectTo: 'login'
        }
    ];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }