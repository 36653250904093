import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, merge, Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, debounceTime, filter, startWith, switchMap, tap, map } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/services/auth.service';
import { CatalogVehiclesService } from 'src/app/services/catalog-vehicles.service';
import { FileStorageService } from 'src/app/services/file-storage.service';
import { UserMarkService } from 'src/app/services/user-mark.service';
import { CRMService } from 'src/app/services/crm.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DealersService } from 'src/app/services/dealers.service';

@Component({
  templateUrl: './visit-survey.component.html',
  styleUrls: ['./visit-survey.component.scss']
})
export class VisitSurveyComponent {
  public ocSection: any[] = [];
  public access: boolean = false;

  public optionSelected?: any;
  public holdOver?: any;

  public surveyJobs: any = [];

  public isFormSend?: boolean = false;
  public isOpenCalendar?: boolean = false;
  public isOpenComment?: boolean = false;

  public newDate: FormControl = new FormControl();
  public newHour: FormControl = new FormControl();

  public comment: FormControl = new FormControl();

  openCloseTr: any[] = [];

  events: any[] = [];

  visitsList: any;

  showActions: boolean = false;
  private vehicleSubscription?: Subscription;

  public openSearch = false;

  public showTable = false;

  public isAdmin: any;
  public rol: any;
  public current_mark: any

  public clientTypesList: any[] = []
  public filterClientTypesCtrl: FormControl = new FormControl('');
  private _filteredClientTypes: BehaviorSubject<any> = new BehaviorSubject(null);
  public filteredClientTypes: Observable<any[]> = this._filteredClientTypes.asObservable();

  public dealerList: any[] = [];
  private _filteredDealer: BehaviorSubject<any> = new BehaviorSubject(null);
  public filteredDealer: Observable<any[]> = this._filteredDealer.asObservable();
  public filterDealerCtrl: FormControl = new FormControl('');

  public clientsList: any[] = [];
  private _filteredClients: BehaviorSubject<any> = new BehaviorSubject(null);
  public filteredClients: Observable<any[]> = this._filteredClients.asObservable();
  public filterClientsCtrl: FormControl = new FormControl('');

  public rzList: any[] = []
  public filterRzCtrl: FormControl = new FormControl('');
  private _filteredRz: BehaviorSubject<any> = new BehaviorSubject(null);
  public filteredRz: Observable<any[]> = this._filteredRz.asObservable();

  public originsList?: any[];
  public filterOriginsCtrl: FormControl = new FormControl('');
  private _filteredOrigins: BehaviorSubject<any> = new BehaviorSubject(null);
  public filteredOrigins: Observable<any[]> = this._filteredOrigins.asObservable();

  public visitTypeList: any[] = [];
  public visitStateList: any[] = [];

  private _filteredVisitType: BehaviorSubject<any> = new BehaviorSubject(null);
  public filteredVisitType: Observable<any[]> = this._filteredVisitType.asObservable();
  public filterVisitTypeCtrl: FormControl = new FormControl('');

  private _filteredVisitState: BehaviorSubject<any> = new BehaviorSubject(null);
  public filteredVisitState: Observable<any[]> = this._filteredVisitState.asObservable();
  public filterVisitStateCtrl: FormControl = new FormControl('');

  public token?: any

  private _updateTable: Subject<void> = new Subject();

  public filters: FormGroup = new FormGroup({
    token: new FormControl()
  });

  public visitInfo: FormGroup = new FormGroup({
    date: new FormControl(),
    time: new FormControl(),
    visit: new FormControl(),
    rz: new FormControl(),
  })

  public surveyJobsEval: FormArray = this.formBuilder.array([])

  constructor(
    private formBuilder: FormBuilder,
    private titleService: Title,
    private authService: AuthService,
    private userMarkService: UserMarkService,
    private catalogVehiclesService: CatalogVehiclesService,
    private fileStorageService: FileStorageService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private dialog: MatDialog,
    private crmService: CRMService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private dealerService: DealersService,

  ) {
    this.titleService.setTitle('IMS - Evaluar Visita');
    this.token = this.router.url.split('/')[2];
    this.filters.get('token')?.setValue(this.token);
  }


  ngAfterViewInit() {
    this.filters.valueChanges.subscribe(_ => { });
    this.vehicleSubscription = merge(
      this.filters.valueChanges.pipe(debounceTime(1000)),
      merge(
      )
        .pipe(debounceTime(500)),
      this.userMarkService.mark.pipe(tap(_ => { })),
      this._updateTable,
    )
      .pipe(
        startWith(null),
        switchMap(_ => this.crmService
          .validateSurveyToken({ ...this.filters.value })
          .pipe(catchError(_ => of([])))
        )
      )
      .subscribe(
        (token: any) => {
          if (token == undefined) { this.visitsList = { data: [] }; }
          else {
            this.token = token.id
            this.access = true
            this.visitInfo.get('date')?.setValue(token.visit.resolution.real_init_date)
            this.visitInfo.get('time')?.setValue(token.visit.resolution.real_init_hour)
            this.visitInfo.get('visit')?.setValue(token.visit.id)
            this.visitInfo.get('rz')?.setValue(token.visit.area_manager.name)
          }
        }
      );
    this.loadSurveyJobs();
  }

  public asFormGroup(control: AbstractControl) { return control as FormGroup; }

  public loadSurveyJobs() {
    this.crmService.getSurveyJob().subscribe(_ => {
      this.surveyJobs = _
      this.surveyJobs.forEach((element: any) => {
        this.surveyJobsEval.push(new FormGroup({ job_id: new FormControl(element.id), note: new FormControl() }))
      });
    })
  }

  public sendSurvey() {
    this.crmService.evaluateSurvey(this.visitInfo.get('visit')?.value, this.surveyJobsEval.value, this.comment.value, this.token).subscribe(_ => {
      this.snackBar.open(
        this.translateService.instant('La encuesta se ha evaluado correctamente'),
        this.translateService.instant('aceptar'),
        {
          panelClass: 'sb-success',
          duration: 4000
        })
    }, err => {
      this.snackBar.open(
        this.translateService.instant('Ha habido un error al evaluar la encuesta'),
        this.translateService.instant('aceptar'),
        {
          panelClass: 'sb-error',
          duration: 4000
        })
    })
  }

  public changeState(valueNewState: number) {
    let data: any = []
    if (valueNewState == 3) {
      data.push({ state: valueNewState, new_date: this.newDate.value, new_hour: this.newHour.value })
    }
    if (valueNewState == 2) {
      data.push({ state: valueNewState })
    }
    if (valueNewState == 6) {
      data.push({ state: valueNewState, comment: this.comment.value })
    }
    this.isOpenComment = false;
    this.isOpenCalendar = false;
    this.isFormSend = true;
    this.crmService.changeVisitState(data[0], this.token.visit.id, this.token.id).subscribe(_ => {
      this.optionSelected = _.visit_state_id;
      this.holdOver = _.visit_hold_over;
      this.comment.setValue(_.visit_canceled.motive);
      this.snackBar.open(
        this.translateService.instant(valueNewState == 2 ? 'Se ha aceptado la cita correctamente' : valueNewState == 3 ? 'Se ha propuesto nueva fecha y hora para la cita correctamente' : 'Se ha cancelado la cita correctamente'),
        this.translateService.instant('aceptar'),
        {
          panelClass: 'sb-success',
          duration: 4000
        }
      );
    }, err => {
      this.snackBar.open(
        this.translateService.instant(valueNewState == 2 ? 'Se ha producido un error al aceptar la cita' : valueNewState == 3 ? 'Se ha producido un error al intentar proponer nueva fecha y hora para la cita' : 'Se ha producido un error al cancelar la cita'),
        this.translateService.instant('aceptar'),
        {
          panelClass: 'sb-error',
          duration: 4000
        }
      );
    })
  }

  public setNote(i: number, value: number) {
    this.surveyJobsEval.at(i).get('note')?.setValue(value);
    this.cdr.detectChanges();
  }



  // ngOnDestroy() {
  //   if (this.vehicleSubscription) {
  //     this.vehicleSubscription.unsubscribe();
  //   }
  // }
}
