import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DealersService {

  constructor(
    private http: HttpClient
  ) { }

  public get(): Observable<any> {
    return this.http.get<any[]>(`${ environment.api }/dealers`);
  }

  public getFilters(data: any): Observable<any> {
    return this.http.get<any[]>(`${ environment.api }/dealers_filters`, {
      params: { ...data }
    });
  }

  public get_active(): Observable<any> {
    return this.http.get<any[]>(`${ environment.api }/dealers_active`);
  }

  public getUserDealer(user_id: number): Observable<any> {
    return this.http.get<any[]>(`${ environment.api }/dealer_user/${ user_id }`);
  }

  public show(dealer_id: number): Observable<any> {
    return this.http.get<any[]>(`${ environment.api }/dealers/${ dealer_id }`);
  }

  public getDocuments(dealer_id: number, type_document: string): Observable<any> {
    if (type_document != undefined) {
      return this.http.get<any>(`${ environment.api }/dealers/${ dealer_id }/documents`, {
        params: { type_document: type_document }
      });
    }
    else {
      return this.http.get<any>(`${ environment.api }/dealers/${ dealer_id }/documents`);
    }
  }

  public postDocuments(dealer_id: number, type_document: string, file: File): Observable<any> {
    let formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('type_document', type_document?.toString())

    return this.http.post<any>(`${ environment.api }/dealers/${ dealer_id }/documents`, formData);
  }

  public deleteDocuments(dealer_id: number, file_id: number): Observable<any>{
    return this.http.delete<any>(`${ environment.api }/dealers/${ dealer_id }/documents/${ file_id }`);
  }

  public showDocument(dealer_id: number, file_id: number): Observable<any>{
    return this.http.get(`${ environment.api }/dealers/${ dealer_id }/documents/${ file_id }`,{
      responseType: 'arraybuffer',
    });
  }

  public getWorkers(dealer_id: number){
    return this.http.get<any[]>(`${ environment.api }/dealer_workers/${ dealer_id }`);
  }

  public getWebData(dealer_id: number){
    return this.http.get<any[]>(`${ environment.api }/dealer_web_data/${ dealer_id }`);
  }

  public getInvoiceData(dealer_id: number){
    return this.http.get<any[]>(`${ environment.api }/dealer_invoice_data/${ dealer_id }`);
  }

  public getDirect(dealer_id: number){
    return this.http.get<any[]>(`${ environment.api }/dealer_direction/${ dealer_id }`);
  }

  public getLastDealerships(mark_id: any): Observable<any>
  {
    const params: any = {};
    if(mark_id && mark_id > 0) { params.mark_id = mark_id; }
    return this.http.get<any[]>(`${ environment.api }/last_dealers`, { params });
  }

}
